// 进修招聘 邓

import { get, put, post, del } from "./base";
import {
  llget,
  drugPut,
  drugPost,
  drugDel,
  postUserId,
  putDragById,
  delUserId,
  drugGet,
  patch,
  getDrugExel,
} from "./base"; //招聘管理页面
import qs from "qs";

// 数据字典
export function getDictionary(data) {
  return drugGet("/v3/educationRecruitDictionary/content", data);
}
export function delDictionary(id) {
  return drugDel(`/v3/educationRecruitDictionary/content/${id}`);
}
export function addDictionary(data) {
  return drugPost("/v3/educationRecruitDictionary/content", data);
}
export function editDictionary(data) {
  return drugPut("/v3/educationRecruitDictionary/content", data);
}

export function getSelect(code) {
  return drugGet(`/v3/educationRecruitDictionary/content/${code}`);
}

// 进修内容管理
export function getEducation(type, title, curPage, pageSize, year, isPublish,isDeadLine) {
  return drugGet(
    `/v3/educationRecruit/info?type=${type}&title=${title}&curPage=${curPage}&pageSize=${pageSize}&year=${year}&isPublish=${isPublish}&isDeadLine=${isDeadLine}`
  );
}
// 修改
export function editEducation(data) {
  return drugPut("/v3/educationRecruit/info", data);
}
// 新增
export function addEducation(data) {
  return drugPost("/v3/educationRecruit/info", data);
}
// 删除
export function delEducation(id) {
  return drugDel(`/v3/educationRecruit/info/${id}`);
}
// 查看推荐状态
export function getRecommend(id) {
  return drugGet(`/v3/educationRecruitRecommend/recommend/${id}`);
}
// 开启推荐
export function postRecommend(data) {
  return drugPost("/v3/educationRecruitRecommend/recommend", data);
}
// 下架推荐
export function delRecommend(id) {
  return drugDel(`/v3/educationRecruitRecommend/recommend/content/${id}`);
}
// 获取推荐内容
export function getRecommendList(curPage, pageSize) {
  return drugGet(
    `/v3/educationRecruitRecommend/recommend?&curPage=${curPage}&pageSize=${pageSize}`
  );
}
// 获取子类
export function getChildEducation(parentId, departmentId) {
  return drugGet(
    `/v3/educationRecruit/info/children?&parentId=${parentId}&departmentId=${departmentId}`
  );
}
// 移动
export function moveRecommendList(move, id) {
  return drugPut(`/v3/educationRecruitRecommend/recommend/${move}/${id}`);
}
// 下架
export function delRecommendList(id) {
  return drugDel(`/v3/educationRecruitRecommend/recommend/${id}`);
}
