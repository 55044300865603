<!-- 进修招聘  推荐内容管理 邓 -->
<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div class="datePicker">
      <div class="body">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :pagination="false"
        >
          <span slot="sort" slot-scope="text, record, index">
            <div
              style="
                display: flex;
                justify-content: start;
                width: 100%;
                margin: auto;
                margin-left: 30px;
              "
            >
              <a
                v-if="index !== 0"
                @click="move('up', record.id)"
                style="margin-right: 20px"
                ><span>上移</span></a
              >

              <a
                v-if="index !== tableList.length - 1"
                @click="move('down', record.id)"
                ><span>下移</span></a
              >
            </div>
          </span>
          <span slot="operation" slot-scope="text, record, index">
            <a-popconfirm
              title="确定下架?"
              ok-text="是"
              cancel-text="否"
              @confirm="del(record.id)"
              @cancel="cancel"
            >
              <a>下架</a>
            </a-popconfirm>
          </span>
        </a-table>
        <div
          style="
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ total }}条记录 第 {{ curPage }} /
            {{ Math.ceil(total / pageSize) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pageSize"
            :total="total"
            v-model="curPage"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRecommendList,
  moveRecommendList,
  delRecommendList,
} from "@/service/trainManage_api";
export default {
  data() {
    return {
      tableList: [],
      total: 0,
      curPage: 1,
      pageSize: 10,
      columns: [
        {
          title: "标题",
          dataIndex: "title",
          width: "27%",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          width: "15%",
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: "16%",
          align: "center",
        },
        {
          title: "创建人",
          width: "10%",
          dataIndex: "userName",
          align: "center",
        },
        {
          title: "排序",
          dataIndex: "sort",
          width: "10%",
          scopedSlots: { customRender: "sort" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "operation" },
          align: "center",
        },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      const res = await getRecommendList(this.curPage, this.pageSize);
      this.tableList = res.data;
      this.total = res.count;
    },
    async move(a, id) {
      const res = await moveRecommendList(a, id);
      if (res.code == 0) {
        this.$message.success("移动成功！");
        this.load();
      }
    },
    async del(id) {
      const res = await delRecommendList(id);
      if (res.code == 0) {
        this.load();
        this.$$message.success("下架成功！");
      }
    },
    // 分页查询
    pageChange(curPage, pageSize) {
      this.curPage = curPage;
      this.pageSize = pageSize;
      this.load();
    },
  },
};
</script>
<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  padding: 20px;
}
.body {
  background-color: #fff;
  display: flex;
  flex-flow: column;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
